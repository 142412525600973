import { Dropdown, Icon} from "semantic-ui-react";
import React from "react";
import {connect} from "react-redux";
import {logoutUser} from "../../../actions/AuthActions";
import {Link} from "react-router-dom";
import "./weblaw_username.css"


class UserName extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    saveLink = () => {
        sessionStorage.setItem("isLoginIn", window.location.pathname +window.location.search)
    }

    setUser = () => {
        if ((this.props.ConfigReducer.configJson.generalOptions.loginShow && this.props.ConfigReducer.configJson.generalOptions.loginShow === 'optional') || sessionStorage.getItem("isLoggedIn")) {
            if(!sessionStorage.getItem("isLoggedIn")){
                return <span className={"userName " +  (this.props.mobile ?  this.props.isCacheView ? "userNameDashboardMobile" : "userNameDashboardMobile": "userNameDashboardComputer") }>
                    <Link onClick={() => this.saveLink()} className={"userName"}  to={"/login"} >
                        Login
                    </Link>
                </span>
            }  else {
                var username = this.props.LEv4Reducer.username;
                return<Dropdown
                    className={"userName"}
                    text={(<span className={"userName " +  (this.props.mobile ?  this.props.isCacheView ? "userNameDashboardMobile" : "userNameDashboardMobile": "userNameDashboardComputer")}><Icon name={"user circle"}></Icon>{username}</span>)}
                    button
                    labeled
                    icon={<></>}
                >
                    <Dropdown.Menu>
                        <Dropdown.Item text="Logout" onClick={() => {
                            this.saveLink();
                            this.props.logoutUser();
                        }}/>
                    </Dropdown.Menu>
                </Dropdown>
            }
        }

        return null;
    }

    render() {
        return this.setUser();

    }
}

const mapDispatchToProps = {
    logoutUser,
};

const mapStateToProps = state => ({
    ...state
})

export default connect(mapStateToProps, mapDispatchToProps)(UserName);
