import React from "react";
import {connect} from "react-redux";
import {Divider, Grid, Header, Loader, Segment, Sidebar} from "semantic-ui-react";
import JumpPortal from "../../MarkedElementsComponents/JumpPortal";
import {goBackToDashboard, toggleSidebar} from "../../../../actions/CacheViewActions";
import SideMenuMobile from "./ContentSegmentMobileComponents/SideMenuMobile";
import OutsideClickHandler from "react-outside-click-handler/esm/OutsideClickHandler";
import OriginalLink from "../../../sharedComponents/OriginalLink";
import TitleLabel from "../../../sharedComponents/TitleLabel/TitleLabel";
import { LangSplitter } from "../../../../services/LangSplitter";

class ContentSegmentMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleOutsideClick = () => {
        this.props.toggleSidebar(false);
    }


    generateLoader = () => {
        if(!this.props.singleDocLoaded)
            return  <Grid container centered stretched columns={1} style={{height: '75vh'}}>
                        <Grid.Column verticalAlign={"middle"}>
                            <Loader inline='centered' active size="large">
                                {this.props.translationsConfig.loading[this.props.guiLanguage]}
                            </Loader>
                        </Grid.Column>
                    </Grid>

        return null
    }

    generateDocumentHeader = () => {
        if(this.props.singleDocLoaded )
            return  <Grid style={{paddingTop: "50px"}}>
                        <Grid.Column width={14}>
                            <Header as='h2'>
                                {LangSplitter.getKeyFromLanguage(this.props.guiLanguage,String(this.props.singleDocumentData.metadataKeywordTextMap["title"]))}
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            {this.props.singleDocumentData.metadataKeywordTextMap !== undefined && this.props.singleDocumentData.metadataKeywordTextMap.originalUrl ?
                            <OriginalLink originalURL={this.props.singleDocumentData.metadataKeywordTextMap.originalUrl}/> : null}
                        </Grid.Column>
                    </Grid>

        return null
    }


    filterAndEnrichConfigAccordingToValuesFromDocument = (element) => {
        element.metadataKeywordTextMap = element.field in this.props.metadataKeywordTextMap
        element.metadataDateMap = element.field.replace(/List$/, '') in this.props.metadataDateMap
        return element.metadataKeywordTextMap || element.metadataDateMap
    }


    hasTitleLabels = () => {
        return this.props.metadataKeywordTextMap &&
            this.props.configJson.filterMenu.filter(element => element.showInCacheViewHeader)
                .filter(element => this.filterAndEnrichConfigAccordingToValuesFromDocument(element)).length>0
    }


    generateHeaderTitleLabels = () => {
        return  <div style={{paddingLeft: "50px", paddingTop: "30px"}}>
                    {this.props.configJson.filterMenu.filter(element => element.showInCacheViewHeader)
                        .filter(element => this.filterAndEnrichConfigAccordingToValuesFromDocument(element))
                        .sort((element1, element2) => element1.headerIndex-element2.headerIndex)
                        .map((element,index) => <TitleLabel item={element}
                                                            key={index}
                                                            keyWordTextValue={this.props.singleDocumentData.metadataKeywordTextMap[element.field.replace(/List$/, '')]}
                                                            dateValue={this.props.metadataDateMap[element.field.replace(/List$/, '') ]}/>)}
                </div>
    }



    render() {
        if (this.props.singleDocumentData.metadataKeywordTextMap && this.props.singleDocumentData.metadataKeywordTextMap["title"] && LangSplitter.getKeyFromLanguage(this.props.guiLanguage, String(this.props.singleDocumentData.metadataKeywordTextMap["title"])))
            document.title = LangSplitter.getKeyFromLanguage(this.props.guiLanguage,String(this.props.singleDocumentData.metadataKeywordTextMap["title"]))
        return  <Sidebar.Pushable style={{"width":"100%"}}>
                    <Sidebar animation="overlay" visible={this.props.showSidebar} direction="left">
                        <OutsideClickHandler disabled={!this.props.showSidebar} onOutsideClick={this.handleOutsideClick}>
                            <SideMenuMobile/>
                        </OutsideClickHandler>
                    </Sidebar>
                    <Sidebar.Pusher dimmed={false}>
                        <Grid columns={1} style={{minHeight: "800px"}}>
                            <Grid.Column only='mobile'>
                                {!this.props.showSidebar ?
                                    <Segment id={"customContentSegment"} padded style={{ "overflowX" : (this.props.setOverflowProperty ? "scroll" : undefined ), paddingLeft: "10px", "whiteSpace": (this.props.configJson.generalOptions.cacheUseWhitespace ? this.props.configJson.generalOptions.cacheUseWhitespace : "inherit" )}}>
                                    {this.generateDocumentHeader()}
                                    {this.props.singleDocLoaded && this.hasTitleLabels() ?  this.generateHeaderTitleLabels() : null}
                                    {this.generateLoader()}
                                    {this.props.singleDocLoaded ? <Divider style={{marginTop: "18px"}}/> : null}
                                    {this.props.parsedJSXMap && this.props.singleDocLoaded ? this.props.parsedJSXMap[LangSplitter.guiLanguageToNr(this.props.guiLanguage)] : null}
                                    {this.props.parsedJSX && this.props.singleDocLoaded ? this.props.parsedJSX : null}
                                    </Segment>: null}
                            </Grid.Column>
                            {this.props.jumpPortalState ? <JumpPortal/> : null}
                        </Grid>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>

    }

}

function mapStateToProps(state) {
    const { URLReducer, CacheViewReducer, DashboardReducer, ConfigReducer } = state
    const {URLString, guiLanguage } = URLReducer
    const parsedJSX = CacheViewReducer.parsedContentForCacheView?.parsedJSX;
    const parsedJSXHeader = CacheViewReducer.parsedContentForCacheView?.parsedJSXHeader;
    const parsedJSXMap = CacheViewReducer.parsedContentForCacheView?.parsedJSXMap;
    const setOverflowProperty = CacheViewReducer.parsedContentForCacheView?.setOverflowProperty;
    const {fetchedDocumentsFromDashboard } = DashboardReducer
    const { jumpPortalState, singleDocumentData, showSidebar, singleDocLoaded } = CacheViewReducer
    const { configJson, translationsConfig } = ConfigReducer
    const nestedMetadataMap = singleDocumentData.nestedMetadataMap;
    const metadataKeywordTextMap = singleDocumentData.metadataKeywordTextMap;
    const metadataDateMap = singleDocumentData.metadataDateMap;
    return {configJson, URLString, translationsConfig, singleDocumentData, fetchedDocumentsFromDashboard, guiLanguage, nestedMetadataMap, metadataKeywordTextMap, metadataDateMap,
        jumpPortalState, showSidebar, singleDocLoaded, parsedJSX, parsedJSXMap, parsedJSXHeader, setOverflowProperty}
}

const mapDispatchToProps = {
    goBackToDashboard,
    toggleSidebar
};

export default connect(mapStateToProps, mapDispatchToProps) (ContentSegmentMobile);