import React from "react";
import {Button, Dimmer, Grid, Icon, Input, Message, TransitionablePortal} from "semantic-ui-react";
import {Redirect} from "react-router";
import {authenticateUser} from "../../actions/AuthActions";
import {connect} from "react-redux";
import {createReactJSXFromString} from "../../services/ReactAndHTMLParserService";
import './weblaw_login.css'
import LanguageSwitch from "../sharedComponents/languageSwitch/LanguageSwitch";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            show: true,
            load: false,
            redirect: false,
            url: "",
            username: "",
            password: "",
            authRequestSend: false
        }
    }

    componentDidMount() {
        this.setState({load: true})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // After redirecting to the appropriate URL we need to reset the variable to false
        // Otherwise there are scenarios in which an unintentional redirect could be performed
        if(this.state.redirect)
            this.setState({redirect:false})
    }

    validateUser = () => {
    if(!this.state.authRequestSend) {
        this.setState({authRequestSend: true})
        this.props.authenticateUser(this.state.username, this.state.password)
            .then((response) => {
                if (sessionStorage.getItem("isLoginIn")) {
                    this.setState({
                        url: sessionStorage.getItem("isLoginIn")
                    })
                    this.setState({
                        redirect: true
                    })
                    window.location.href = window.location.href = sessionStorage.getItem("isLoginIn");
                    sessionStorage.removeItem("isLoginIn")
                } else {
                    window.location.href = this.props.url.replace("login", "")
                }
            })
            .catch((error) => {
                this.setState({
                    error: error.response.data.error,
                    show: true,
                    authRequestSend: false
                })
                this.resetLoginForm();
            });
    }
    };

    nameChange = (event) => {
        this.setState({
            username : event.target.value
        });
    };
    passwordChange = (event) => {
        this.setState({
            password : event.target.value
        });
    };


    resetLoginForm = () => {
       this.setState({username: "", password: ""})
    };

    handleEnterClick = (e) => {
        if (e.key === 'Enter') {
            this.validateUser();
        }
    }

    render() {
        var jsxNewLoginCreation = this.props.translationsConfig.newLoginCreation ?  createReactJSXFromString(String(this.props.translationsConfig.newLoginCreation[this.props.guiLanguage])) : undefined
        var jsxPasswordRecovery = this.props.translationsConfig.passwordRecovery ?  createReactJSXFromString(String(this.props.translationsConfig.passwordRecovery[this.props.guiLanguage])) : undefined
        var jsxBuySingleContentInfo = this.props.translationsConfig.buySingleContentInfo ?  createReactJSXFromString(String(this.props.translationsConfig.buySingleContentInfo[this.props.guiLanguage])) : undefined


        if(this.state.load)
            return <Grid centered columns={2} textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                <Grid.Row columns={1}>
                        <Grid.Column>
                            <LanguageSwitch/>
                        </Grid.Column>
                </Grid.Row>
                <Grid.Row className={"centered"}>
                        <Grid.Column className={"leftColumn"}>
                            <div className={"loginTitle"}>
                                {this.props.translationsConfig.loginHeadline[this.props.guiLanguage]}
                            </div>
                            <Input className={"upperInput"} fluid icon type='text' size={"big"}
                                   onChange={this.nameChange}
                                   value={this.state.username}
                                   onKeyDown={this.handleEnterClick}
                                   iconPosition='left'
                                   placeholder={this.props.translationsConfig.usernamePlaceholder[this.props.guiLanguage]}>
                                <Icon className={"inputIcon"} name='user circle'/>
                                <input id="nameInput" className={"inputField"} type="text"/>
                            </Input>
                            <Input className={"lowerInput"} fluid icon type='password' size={"big"}
                                   onChange={this.passwordChange}
                                   value={this.state.password}
                                   onKeyDown={this.handleEnterClick}
                                   iconPosition='left'
                                   placeholder={this.props.translationsConfig.passwordPlaceholder[this.props.guiLanguage]}>
                                <Icon className={"inputIcon"} name='lock' />
                                <input id="passwordInput" className={"inputField"} type="password"/>
                            </Input>
                            <Button id="loginButton" className="leftButtons" primary fluid size='large' onClick={this.validateUser}>
                                Login
                            </Button>
                            {jsxNewLoginCreation ?
                                <Button id="newLoginButton" className="leftButtons" >
                                    {jsxNewLoginCreation}
                                </Button>: null}
                            {this.state.redirect && <Redirect to={this.state.url} />}
                            {this.state.show && this.state.error && jsxPasswordRecovery ?
                                <Button id={"passwordRecoveryButton"} className="leftButtons">
                                    {jsxPasswordRecovery}
                                </Button> : null}
                        </Grid.Column>
                        <Grid.Column className={"rightColumn"} >
                            <div key={1} className={"descriptionTitle"}>
                                {this.props.translationsConfig?.descriptionLogin ?
                                    String(this.props.translationsConfig.descriptionLoginTitle[this.props.guiLanguage])
                                        .split( "\\n").map((element,index) => <p key={index}>{element}<br /> </p>) : null}
                            </div>
                            <div key={2} className={"descriptionLogin"}>
                                {this.props.translationsConfig?.descriptionLogin ?
                                    String(this.props.translationsConfig.descriptionLogin[this.props.guiLanguage])
                                        .split( "\\n").map((element,index) => <p key={index}>{element}<br /> </p>) :null }
                            </div>
                            {jsxBuySingleContentInfo ?
                                <Button className="rightButtons" positive>
                                    {jsxBuySingleContentInfo}
                                </Button>
                            : null}
                        </Grid.Column>
                </Grid.Row>
                        {this.state.show && this.state.error ?
                            <>
                                <Dimmer active inverted/>
                                <TransitionablePortal open={true} onClose={()=>this.setState({show: false})} transition={{animation: 'fly down', duration: 1500, open: false}}>
                                    <Message color='yellow' size="large" style={{width: '400px', left: '40%', position: 'fixed', top: '5%', zIndex: 1000}}>
                                        <Message.Header>
                                            {this.state.error}
                                        </Message.Header>
                                    </Message>
                                </TransitionablePortal>
                            </>: null }
                    </Grid>


        return null
    }


}

const mapDispatchToProps = {
    authenticateUser
};

function mapStateToProps(state) {
    const {URLReducer, ConfigReducer } = state
    const { guiLanguage } = URLReducer
    const { translationsConfig} = ConfigReducer
    return {guiLanguage, translationsConfig}
}

export default connect(mapStateToProps, mapDispatchToProps) (Login);