import React from "react";
import {Grid,Image, Sticky} from "semantic-ui-react";
import {clearFilters} from "../../../../../actions/FilterActions";
import {connect} from "react-redux";
import SearchBoxComputer from "./HeaderMenuComputerComponents/searchBoxComputer/SearchBoxComputer";
import ItemGroupHeaderComputer
    from "./HeaderMenuComputerComponents/itemGroupHeaderComputer/ItemGroupHeaderComputer";

import "./weblaw_headermenu_computer.css"
import LinkButtons from "../../../../sharedComponents/auxiliaryPopUp/LinkButtons";

class HeaderMenuComputer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLanguageSwitch : this.props.configJson.generalOptions.showLanguageSwitch,
            burgerIconSrc : this.props.configJson?.logoConfig?.burgermenu,
            redirect: false,
            isSticking: false,
            showPopUp : false,
            width: 0,
            marginRight: 0
        };

        this.handleResize = this.handleResize.bind(this);
    }


    componentDidMount() {
        this.handleResize();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        // We use custom width and margin for the elements in this component
        // The width and margin is computed based on the viewport width, which is provided by some javascript events through the redux store
        if(prevProps.viewportWidth !== this.props.viewportWidth){
            this.handleResize();
        }
    }

    handleResize = () =>  {
        this.setState({width: (500 + ((this.props.viewportWidth-450)*0.90 >1150 ? 1150 : (this.props.viewportWidth-450)*0.90 ))})
        this.setState({marginRight: ((this.props.viewportWidth-450)*0.90 >1150 ? 2 : 2.5 )})
    }


    openNewTabWithURL = (url) => {
        window.open(url, '_blank');
    }

    render() {

        return  <>
                    <Grid centered stackable padded={false}>
                        <Grid.Column style={{width:(this.state.width) + "px" }} className={"mainGridColumn"}>
                            <Grid.Row id={"firstMenuItem"}>
                                <LinkButtons />
                            </Grid.Row>
                        </Grid.Column>
                    </Grid>
                    <Grid centered stackable padded={false}>
                        <Grid.Column className={"mainGridColumn"} width={16}>
                            <Sticky offset={0} context={this.props.contextRef} onStick={()=>this.setState({isSticking: true})} onUnstick={()=>this.setState({isSticking: false})}>
                                <Grid className={ (this.state.isSticking ? "stickySearchRow": "")} style={{margin: "0px"}} centered stackable padded={false}>
                                    <Grid.Column  style={{width:(this.state.width -40) + "px"}} className={"mainGridColumn"}>
                                        <Grid.Row >
                                            {this.props?.logoFromTranslationsConfig?.logoConfig?.headerlogo?.logoB64 ?
                                            <Image  src={"data:image/svg+xml;base64," + this.props.logoFromTranslationsConfig.logoConfig.headerlogo.logoB64}
                                                    alt="logo"
                                                    id={"lev4Image"}
                                                    style={{marginRight: this.props.logoFromTranslationsConfig.logoConfig.headerlogo.marginRight + "px", marginTop:"3px"}}
                                                    onClick={() => window.location.href ="/" + "?guiLanguage=" + this.props.guiLanguage}/>
                                                : null}
                                            <SearchBoxComputer search={this.props.translationsConfig.search[this.props.guiLanguage]}/>
                                        </Grid.Row>
                                    </Grid.Column>
                                </Grid>
                            </Sticky>
                        </Grid.Column>
                    </Grid>
                    <Grid centered stackable padded={false} style={{margin: "0px"}}>
                        <Grid.Column  style={{width:(this.state.width-40) + "px"}} className={"mainGridColumn"}>
                            <Grid.Row>
                                <ItemGroupHeaderComputer/>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid>
                </>
    }
}

const mapDispatchToProps = {
    clearFilters
};

function mapStateToProps(state) {
    const { LEv4Reducer, URLReducer, ConfigReducer } = state
    const {filters, guiLanguage} = URLReducer
    const {configJson, translationsConfig, logoFromTranslationsConfig } = ConfigReducer
    const {viewportWidth} = LEv4Reducer
    return {configJson, translationsConfig, filters, guiLanguage, logoFromTranslationsConfig,viewportWidth}
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenuComputer);