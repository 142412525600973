import React from "react";
import {  Popup} from "semantic-ui-react";
import OutsideClickHandler from "react-outside-click-handler/esm/OutsideClickHandler";
import {connect} from "react-redux";
import {changeGuiLanguage} from "../../../actions/DashboardAction";
import "./weblaw_languageswitch.css"

const mapDispatchToProps = {
    changeGuiLanguage,
};

class LanguageSwitch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popUpOpened : false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let languageNew = this.props.guiLanguage
        let languageOld = prevProps.guiLanguage

        // If the language pop-up is opened, and we change the language value we need to close the pop-up
        if(this.state.popUpOpened && languageNew !== languageOld)
            this.setState({popUpOpened: false})
    }

    handleClickOnOption = (element) => {
        this.setState({popUpOpened: false});
        this.props.changeGuiLanguage(element.value)
    }


    generateLanguagePopUp = () => {
        var jsxLanguageOptions = <OutsideClickHandler onOutsideClick={()=>this.setState({popUpOpened: false})}>
            <div className="lang_wrap show" >
                {this.props.translationsConfig.languageOptions
                    .map((element, index) =>
                        <a key={index} onClick={()=> this.handleClickOnOption(element)} style={{cursor: "pointer"}}
                           className={"navig loose" + (this.props.guiLanguage === element.value ? " selected_page" : "")}>
                            {element.value}
                        </a>)}
            </div>
        </OutsideClickHandler>

        var jsxButton = <span
            style={{cursor: "pointer"}}
            className={"languageSwitch " +  (this.props.mobile ?  this.props.isCacheView ? "languageSwitchCacheViewMobile" : "languageSwitchDashboardMobile": "languageSwitchDashboardComputer") }>
                {this.props.guiLanguage}
            </span>

        return <span style={{cursor: "pointer"}} onClick={()=> this.setState({popUpOpened: true})}>
                        <Popup open={this.state.popUpOpened}
                               content={jsxLanguageOptions}
                               basic
                               trigger={jsxButton} />
                    </span>
    }

    generateAllLanguage = () => {
        return  <span style={{cursor: "pointer", marginRight: "15px", marginTop: "13px"}}
                      className={"languageSwitch " + (this.props.mobile ? this.props.isCacheView ? "languageSwitchCacheViewMobile" : "languageSwitchDashboardMobile": "languageSwitchDashboardComputer")}>
                    {this.props.translationsConfig.languageOptions
                        .map((element, index) =>
                            <a key={index} onClick={()=> this.handleClickOnOption(element)} style={{cursor: "pointer"}}
                               className={"navig loose" + ( this.props.guiLanguage === element.value ? " selected_page" : "")}>
                                {element.value}
                            </a>)}
                </span>
    }


    render() {
        var waitTillStateIsLoaded = this.props.guiLanguage ? true : false;

        if(this.props.translationsConfig && Object.entries(this.props.translationsConfig.languageOptions).length > 1 && waitTillStateIsLoaded) {
            return this.generateLanguagePopUp()
        }

        return null;
    }

}

function mapStateToProps(state) {
    const { LEv4Reducer, URLReducer, ConfigReducer } = state
    const {configJson, translationsConfig } = ConfigReducer
    const {mobile, isCacheView} = LEv4Reducer
    const { guiLanguage,filters } = URLReducer
    return {configJson, translationsConfig, filters, guiLanguage, mobile, isCacheView}
}
export default connect(mapStateToProps, mapDispatchToProps) (LanguageSwitch);