import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Icon, Popup} from "semantic-ui-react";
import UserName from "../userName/UserName";
import LanguageSwitch from "../languageSwitch/LanguageSwitch";

import "./weblaw_auxiliarypopup.css"
// this component builds buttons according to the linkButtons element in the guiConfig
// it's displayed in the upper right corner of the dashboard and cache view
class LinkButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopUp : false
        };
    }

    openNewTabWithURL = (url) => {
        window.open(url, '_blank');
    }

    generatePopUpContent = () => {
        return  <div>
                    {this.props.configJson?.linkButtons ? this.props.configJson.linkButtons.map((helpButton,index ) =>
                        <div className={"helpButtons"} key={index} onClick={() => this.openNewTabWithURL(helpButton.link[this.props.guiLanguage])}>
                            {helpButton.label[this.props.guiLanguage]}
                        </div>)
                    : null}
                </div>
    }

    generatePopUpTrigger = () => {
        return <Icon style={{cursor: "pointer", marginTop: (this.props.mobile && this.props.isCacheView ? "10px" : "25px"), marginRight: "25px"}} id={"barIcon"} name={this.state.showPopUp ? "close" :'bars'}/>
    }

    render() {
        return  <>
                    <Popup id={"userAndButtonsPopup"} onOpen={()=>this.setState({showPopUp: true})}  onClose={()=>this.setState({showPopUp: false})}  basic
                           content={this.generatePopUpContent()}
                           trigger={this.generatePopUpTrigger()}
                           on={"click"} />
                    <UserName/>
                    <LanguageSwitch/>
                </>

    }
}

function mapStateToProps(state) {
    const { LEv4Reducer, URLReducer, ConfigReducer } = state
    const { guiLanguage } = URLReducer
    const {configJson, translationsConfig } = ConfigReducer
    const {mobile,isCacheView} = LEv4Reducer
    return {configJson, translationsConfig, guiLanguage, mobile, isCacheView};
}

export default connect(mapStateToProps,)(LinkButtons);
